import { v4 as uuidv4 } from 'uuid'

class HTTPError extends Error {
  constructor(response) {
    super()
    this.response = response
  }
}

class Service {
  constructor(prefix) {
    this.prefix = prefix
  }

  async fetch(rpc, request) { // takes proto request, returns proto response
    const url = this.prefix + rpc
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    })
    if (!response.ok) {
      throw new HTTPError(response)
    }
    return response.json()
  }
}

export class AssessmentService extends Service {
  constructor() {
    super('/twirp/limitless.api.AssessmentService/')
  }

  async getAssessments(getAssessmentsRequest) {
    const getAssessmentResponse = await this.fetch('GetAssessments', getAssessmentsRequest)
    return getAssessmentResponse
  }

  async takeAssessment(takeAssessmentRequest) {
    const takeAssessmentResponse = await this.fetch('TakeAssessment', takeAssessmentRequest)
    return takeAssessmentResponse
  }

  async recordMetric(recordMetricRequest) {
    const recordMetricResponse = await this.fetch('RecordMetric', recordMetricRequest)
    return recordMetricResponse
  }

  async finishAssessment(finishAssessmentRequest) {
    const finishAssessmentResponse = await this.fetch('FinishAssessment', finishAssessmentRequest)
    return finishAssessmentResponse
  }

  // todo - editor apis like create an assessment, update its spec, and deploy it
}

// todo - auth service

const assessmentService = new AssessmentService()

export default assessmentService
