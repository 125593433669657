export function WithLabel({id, label, children}) {
  return (
    <div>
      <label htmlFor={id} style={{display: 'block', fontSize: '18px', marginBottom: '8px'}}>{label}</label>
      {children}
    </div>
  )
}

export default function Textfield({style, ...rest}) {
  style = {
    border: '1px solid grey',
    borderRadius: '5px',
    padding: '10px',
    width: '100%',
    boxSizing: 'border-box',
    fontSize: '18px',
    ...style
  }
  return <input type="text" style={style} {...rest} />
}
