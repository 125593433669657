import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetAssessmentById } from '../hooks'
import { AssessmentBuilder } from '../components/Assessment'
import PageContainer from '../components/PageContainer'
import Button from '../components/Button'

function PropertyEditor(props) {
  const style = {
    minWidth: '250px',
    minHeight: '500px',
    backgroundColor: 'whitesmoke',
    padding: '20px',
    boxSizing: 'border-box',
    borderRadius: '5px'
  }
  return (
    <div style={style}>
      <span><b>Screen Properties will go here</b></span>
    </div>
  )
}

export default function EditAssessmentWithId({client}) {
  const { specid } = useParams()
  const [remoteSpec, setRemoteSpec] = useState(undefined)
  const [localSpec, setLocalSpec] = useState(undefined)

  useEffect(() => setLocalSpec(remoteSpec), [remoteSpec])

  useEffect(() => {
    client.getAssessments({})
      .then(response => {
        const found = response.items.find(item => item.specid === specid)
        if (found) {
          setRemoteSpec(JSON.parse(found.spec))
        }
      })
      .catch(e => console.log(e))
  }, [])

  return (
    <PageContainer>
      <h1>Edit Assessment</h1>
      <div style={{display: 'flex', gap: '20px'}}>
        {localSpec && <AssessmentBuilder spec={localSpec} onChangeSpec={s => setLocalSpec(s)} />}
        <div>
          <PropertyEditor />
        </div>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-start', gap: '10px', marginTop: '30px'}}>
        <Button disabled>Cancel</Button>
        <Button disabled>Save Changes</Button>
      </div>
    </PageContainer>
  )
}
