import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { APIAssessment } from '../components/Assessment'

export default function AssessmentPage({client}) {
  const { specid } = useParams()
  const [assessmentId, setAssessmentId] = useState('')

  useEffect(() => {
    client.takeAssessment({specid})
      .then(response => {setAssessmentId(response.assessmentid)})
      .catch(e => console.log(e))
  }, [])
  return (
    <main style={{display: 'flex', justifyContent: 'center'}}>
      {assessmentId && <APIAssessment assessmentId={assessmentId} client={client} />}
    </main>
  )
}
