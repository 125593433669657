import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useGetAssessmentById } from '../hooks'
import { Assessment } from '../components/Assessment'
import PageContainer from '../components/PageContainer'

export default function AssessmentPage({client}) {
  const { specid } = useParams()
  const [state, setState] = useState({
    index: 0,
    isKickout: false,
    kickoutReason: "",
    isCompleted: false,
    completed: "",
    answers: {}
  })
  const [spec, setSpec] = useState(undefined)
  useEffect(() => {
    client.getAssessments({})
      .then(response => {
        const found = response.items.find(item => item.specid === specid)
        if (found) {
          setSpec(JSON.parse(found.spec))
        }
      })
      .catch(e => console.log(e))
  }, [])

  const onSubmit = () => {
    setState(s => ({...s, index: Math.min(s.index+1, spec.children.length - 1)}))
  }
  const onEvent = () => {}
  return (
    <main style={{display: 'flex', justifyContent: 'center'}}>
      <div>
        <h1>Preview Assessment</h1>
        <p>Metrics are not collected in preview mode</p>
        {spec && <Assessment spec={spec} state={state} onSubmit={onSubmit} onEvent={onEvent}/>}
      </div>
    </main>
  )
}
