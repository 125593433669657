import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom'
import RouterLink from './components/RouterLink'
import TakeAssessment from './pages/TakeAssessment'
import TakeAssessmentWithId from './pages/TakeAssessmentWithId'
import PreviewAssessmentWithId from './pages/PreviewAssessmentWithId'
import EditAssessmentWithId from './pages/EditAssessmentWithId'
import Dashboard from './pages/Dashboard'
import Experimental from './pages/Experimental'
import assessmentService from './api/api'
import { DefaultAssessmentService } from './client/service'

const client = new DefaultAssessmentService('', window.fetch.bind(window))

function NavBar() {
  return (
    <div style={{display: 'flex', justifyContent: 'center', margin: '40px'}}>
      <div style={{display: 'flex', gap: '20px'}}>
        <RouterLink to="/take-assessment">Take Assessment</RouterLink>
        <RouterLink to="/dashboard">Dashboard</RouterLink>
      </div>
    </div>
  )
}

function App() {
  const style = {marginBottom: '50px'}
  return (
    <div style={style}>
      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path="/" element={<TakeAssessment client={client} />} />
          <Route path="/take-assessment" element={<TakeAssessment client={client} />} />
          <Route path="/take-assessment/:specid" element={<TakeAssessmentWithId client={client} />} />
          <Route path="/dashboard" element={<Dashboard client={client} />} />
          <Route path="/preview-assessment/:specid" element={<PreviewAssessmentWithId client={client} />} />
          <Route path="/edit-assessment/:specid" element={<EditAssessmentWithId client={client} />} />
          <Route path="/experimental" element={<Experimental client={client} />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
