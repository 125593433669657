import { useEffect, createElement } from 'react'
import Button from './Button'
import Textfield from './Textfield'

export function AssessmentButton({onClick, style, children, ...rest}) {
  return (
    <Button onClick={onClick} style={{minWidth: '200px', ...style}} {...rest}>{children}</Button>
  )
}

function AssessmentRadio({name, value, defaultChecked, onChange}) {
  return <input name={name} value={value} defaultChecked={defaultChecked} type="radio" style={{width: '20px', height: '20px'}} onChange={e => onChange(e.target.value)} />
}

export function AssessmentScreenTitle({text}) {
  const Dot = () => (
    <span
      style={{
        display: 'inline-block',
        width: '3px',
        height: '3px',
        borderRadius: '50%',
        backgroundColor: 'lightgrey'}} />
  )
  const dots = [0, 1, 2].map(i => <Dot key={i} />)
  return (
    <div style={{display: 'inline-flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
      <span style={{fontSize: '18px', color: 'blue'}}>{text}</span>
      <div style={{display: 'flex', gap: '5px'}}>{dots}</div>
    </div>
  )
}

function ComponentColumn({children}) {
  return <div style={{display: 'flex', flexDirection: 'column'}}>{children}</div>
}

function ComponentRow({justify, children}) {
  var j = 'flex-start'
  if (justify === 'center') {
    j = 'center'
  } else if (justify === 'end') {
    j = 'flex-end'
  }
  return <div style={{display: 'flex', justifyContent: j}}>{children}</div>
}

function ComponentParagraph({text}) {
  return <p style={{fontSize: '18px', margin: 0, lineHeight: 'normal'}}>{text}</p>
}

function ComponentTextfieldQuestion({id, question, value, onEvent}) {
  const labelStyle = {
    display: 'block',
    fontSize: '18px',
    marginBottom: '5px',
  }
  return (
    <div>
      <label htmlFor={id} style={labelStyle}>{question}</label>
      <Textfield id={id} value={value || ''} onChange={(e) => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: e.target.value})} onFocus={() => onEvent({componentId: id, type: 'INPUT_SELECTED'})} onBlur={() => onEvent({componentId: id, type: 'INPUT_DESELECTED'})}/>
    </div>
  )
}

function ComponentYesNoRadioQuestion({id, question, value, onEvent}) {
  const style = {
    display: 'flex',
    gap: '20px',
    justifyContent: 'space-between',
    padding: '20px',
    border: '1px solid lightgrey',
    borderRadius: '5px'
  }
  return (
    <div style={style}>
      <span>{question}</span>
      <div style={{display: 'flex', flexShrink: 0}}>
        <AssessmentRadio name={id} value="yes" defaultChecked={value === "yes"} onChange={(v) => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: v})} />
        <AssessmentRadio name={id} value="no" defaultChecked={value === "no"} onChange={(v) => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: v})} />
      </div>
    </div>
  )
}

function MultiChoiceButtons({id, question, onEvent}) {
  return (
    <div>
      <label>{question}</label>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <AssessmentButton onClick={() => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: 'yes'})}>Yes</AssessmentButton>
        <AssessmentButton onClick={() => onEvent({componentId: id, type: 'UPDATED_ANSWER', newValue: 'no'})}>No</AssessmentButton>
      </div>
    </div>
  )
}

function ComponentContinueButton({onClick}) {
  return <AssessmentButton onClick={onClick}>Continue</AssessmentButton>
}

export function createComponent(componentspec, getValue, onEvent) { // create read only component
  var renderedchildren = []
  if (componentspec.children) {
    renderedchildren = componentspec.children.map(c => createComponent(c, getValue, onEvent))
  }

  const components = {
    'Paragraph': ComponentParagraph,
    'TextfieldQuestion': ComponentTextfieldQuestion,
    'YesNoRadioQuestion': ComponentYesNoRadioQuestion,
    'Row': ComponentRow,
    'Column': ComponentColumn,
    'MultiChoiceButtons': MultiChoiceButtons
  }

  const component = components[componentspec.type]
  if (component === undefined) {
    return null
  }
  return createElement(component, {...componentspec, key: componentspec.id, value: getValue(componentspec.id), onEvent: onEvent}, renderedchildren)
}
