export default function Modal({children, onClose}) {
  const outerStyle = {
    position: 'fixed',
    backgroundColor: 'rgba(0,0,0,0.3)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
  const innerStyle = {
    backgroundColor: 'white',
    borderRadius: '0.5em',
    padding: '2em'
  }
  return (
    <div style={outerStyle} onClick={onClose}>
      <div style={innerStyle} onClick={e => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}
