"use strict";
exports.__esModule = true;
exports.DefaultAuthService = exports.DefaultAssessmentService = void 0;
var twirp_1 = require("./twirp");
var GetAssessmentsRequestToJSON = function (_) {
    return {};
};
var JSONToGetAssessmentsResponseItem = function (m) {
    if (m === null) {
        return null;
    }
    return {
        specid: ((m.specid) ? m.specid : m.specId),
        created: m.created,
        isdeployed: ((m.isdeployed) ? m.isdeployed : m.isDeployed),
        spec: m.spec
    };
};
var JSONToGetAssessmentsResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        items: m.items.map(JSONToGetAssessmentsResponseItem)
    };
};
var TakeAssessmentRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        specId: m.specid
    };
};
var JSONToTakeAssessmentResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentid: ((m.assessmentid) ? m.assessmentid : m.assessmentId),
        spec: m.spec
    };
};
var AssessmentAnswerToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        questionId: m.questionid,
        stringValue: m.stringvalue,
        intValue: m.intvalue,
        boolValue: m.boolvalue
    };
};
var JSONToAssessmentAnswer = function (m) {
    if (m === null) {
        return null;
    }
    return {
        questionid: ((m.questionid) ? m.questionid : m.questionId),
        stringvalue: ((m.stringvalue) ? m.stringvalue : m.stringValue),
        intvalue: ((m.intvalue) ? m.intvalue : m.intValue),
        boolvalue: ((m.boolvalue) ? m.boolvalue : m.boolValue)
    };
};
var JSONToAssessmentState = function (m) {
    if (m === null) {
        return null;
    }
    return {
        iskickout: ((m.iskickout) ? m.iskickout : m.isKickout),
        kickoutreason: ((m.kickoutreason) ? m.kickoutreason : m.kickoutReason),
        index: m.index,
        iscompleted: ((m.iscompleted) ? m.iscompleted : m.isCompleted),
        completed: m.completed,
        answers: m.answers.map(JSONToAssessmentAnswer)
    };
};
var ScreenStateToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        answers: m.answers.map(AssessmentAnswerToJSON)
    };
};
var SubmitScreenRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid,
        screenState: ScreenStateToJSON(m.screenstate)
    };
};
var JSONToSubmitScreenResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        state: JSONToAssessmentState(m.state)
    };
};
var GetStateRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid
    };
};
var JSONToGetStateResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        state: JSONToAssessmentState(m.state),
        spec: m.spec
    };
};
var RecordMetricRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid,
        data: m.data
    };
};
var JSONToRecordMetricResponse = function (_) {
    return {};
};
var RecordEventRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        assessmentId: m.assessmentid,
        eventType: m.eventtype,
        data: m.data
    };
};
var JSONToRecordEventResponse = function (_) {
    return {};
};
var FinishAssessmentRequestToJSON = function (m) {
    if (m === null) {
        return null;
    }
    return {
        recordId: m.recordid
    };
};
var JSONToFinishAssessmentResponse = function (_) {
    return {};
};
var CreateUserRequestToJSON = function (_) {
    return {};
};
var JSONToCreateUserResponse = function (_) {
    return {};
};
var GetRolesRequestToJSON = function (_) {
    return {};
};
var JSONToGetRolesResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        roles: m.roles
    };
};
var GetPermissionsRequestToJSON = function (_) {
    return {};
};
var JSONToGetPermissionsResponse = function (m) {
    if (m === null) {
        return null;
    }
    return {
        permissions: m.permissions
    };
};
var DefaultAssessmentService = /** @class */ (function () {
    function DefaultAssessmentService(hostname, fetch, writeCamelCase, headersOverride) {
        if (writeCamelCase === void 0) { writeCamelCase = false; }
        if (headersOverride === void 0) { headersOverride = {}; }
        this.pathPrefix = "/twirp/limitless.api.AssessmentService/";
        this.hostname = hostname;
        this.fetch = fetch;
        this.writeCamelCase = writeCamelCase;
        this.headersOverride = headersOverride;
    }
    DefaultAssessmentService.prototype.getAssessments = function (getAssessmentsRequest) {
        var url = this.hostname + this.pathPrefix + "GetAssessments";
        var body = getAssessmentsRequest;
        if (!this.writeCamelCase) {
            body = GetAssessmentsRequestToJSON(getAssessmentsRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGetAssessmentsResponse);
        });
    };
    DefaultAssessmentService.prototype.takeAssessment = function (takeAssessmentRequest) {
        var url = this.hostname + this.pathPrefix + "TakeAssessment";
        var body = takeAssessmentRequest;
        if (!this.writeCamelCase) {
            body = TakeAssessmentRequestToJSON(takeAssessmentRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToTakeAssessmentResponse);
        });
    };
    DefaultAssessmentService.prototype.submitScreen = function (submitScreenRequest) {
        var url = this.hostname + this.pathPrefix + "SubmitScreen";
        var body = submitScreenRequest;
        if (!this.writeCamelCase) {
            body = SubmitScreenRequestToJSON(submitScreenRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToSubmitScreenResponse);
        });
    };
    DefaultAssessmentService.prototype.getState = function (getStateRequest) {
        var url = this.hostname + this.pathPrefix + "GetState";
        var body = getStateRequest;
        if (!this.writeCamelCase) {
            body = GetStateRequestToJSON(getStateRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGetStateResponse);
        });
    };
    DefaultAssessmentService.prototype.recordMetric = function (recordMetricRequest) {
        var url = this.hostname + this.pathPrefix + "RecordMetric";
        var body = recordMetricRequest;
        if (!this.writeCamelCase) {
            body = RecordMetricRequestToJSON(recordMetricRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToRecordMetricResponse);
        });
    };
    DefaultAssessmentService.prototype.recordEvent = function (recordEventRequest) {
        var url = this.hostname + this.pathPrefix + "RecordEvent";
        var body = recordEventRequest;
        if (!this.writeCamelCase) {
            body = RecordEventRequestToJSON(recordEventRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToRecordEventResponse);
        });
    };
    DefaultAssessmentService.prototype.finishAssessment = function (finishAssessmentRequest) {
        var url = this.hostname + this.pathPrefix + "FinishAssessment";
        var body = finishAssessmentRequest;
        if (!this.writeCamelCase) {
            body = FinishAssessmentRequestToJSON(finishAssessmentRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToFinishAssessmentResponse);
        });
    };
    return DefaultAssessmentService;
}());
exports.DefaultAssessmentService = DefaultAssessmentService;
var DefaultAuthService = /** @class */ (function () {
    function DefaultAuthService(hostname, fetch, writeCamelCase, headersOverride) {
        if (writeCamelCase === void 0) { writeCamelCase = false; }
        if (headersOverride === void 0) { headersOverride = {}; }
        this.pathPrefix = "/twirp/limitless.api.AuthService/";
        this.hostname = hostname;
        this.fetch = fetch;
        this.writeCamelCase = writeCamelCase;
        this.headersOverride = headersOverride;
    }
    DefaultAuthService.prototype.createUser = function (createUserRequest) {
        var url = this.hostname + this.pathPrefix + "CreateUser";
        var body = createUserRequest;
        if (!this.writeCamelCase) {
            body = CreateUserRequestToJSON(createUserRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToCreateUserResponse);
        });
    };
    DefaultAuthService.prototype.getRoles = function (getRolesRequest) {
        var url = this.hostname + this.pathPrefix + "GetRoles";
        var body = getRolesRequest;
        if (!this.writeCamelCase) {
            body = GetRolesRequestToJSON(getRolesRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGetRolesResponse);
        });
    };
    DefaultAuthService.prototype.getPermissions = function (getPermissionsRequest) {
        var url = this.hostname + this.pathPrefix + "GetPermissions";
        var body = getPermissionsRequest;
        if (!this.writeCamelCase) {
            body = GetPermissionsRequestToJSON(getPermissionsRequest);
        }
        return this.fetch((0, twirp_1.createTwirpRequest)(url, body, this.headersOverride)).then(function (resp) {
            if (!resp.ok) {
                return (0, twirp_1.throwTwirpError)(resp);
            }
            return resp.json().then(JSONToGetPermissionsResponse);
        });
    };
    return DefaultAuthService;
}());
exports.DefaultAuthService = DefaultAuthService;
