import React from 'react'
import { useState, useEffect } from 'react'
import AtlasKitInlineEdit from '@atlaskit/inline-edit'
import AtlasKitTextfield from '@atlaskit/textfield'
import AtlasKitTextarea from '@atlaskit/textarea'
import Button from './Button'
import Textfield from './Textfield'
import { updateNodeById } from '../spec/utils'

import { createComponent, AssessmentScreenTitle, AssessmentButton } from './AssessmentComponents'

export function Screen({screenSpec, isLoading, onEvent, onSubmit}) {
  const [screenState, setScreenState] = useState({answers: {}})

  const outerStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    borderRadius: '5px',
    boxShadow: '0px 0px 10px lightgrey',
    padding: '1.5em 2.5em 3em 2.5em',
    boxSizing: 'border-box',
    backgroundColor: 'white'
  }

  const innerStyle = {
    flex: '1 0 400px'
  }

  useEffect(() => {
    onEvent({componentId: screenSpec.id, type: 'SCREEN_DISPLAYED'})
  }, [screenSpec.id])

  const wrappedOnEvent = (e) => {
    if (e.type === 'UPDATED_ANSWER') {
      setScreenState(s => ({answers: {...s.answers, [e.componentId]: e.newValue}}))
    }
    onEvent(e)
  }

  const getValue = qid => screenState.answers[qid]

  const children = screenSpec.children.map(c => createComponent(c, getValue, wrappedOnEvent))
  return (
    <div style={outerStyle}>
      <AssessmentScreenTitle text={screenSpec.title} />
      <div style={innerStyle}>
        {children}
      </div>
      {screenSpec.showContinueButton && <AssessmentButton disabled={isLoading} onClick={() => onSubmit(screenState)}>{isLoading ? 'Loading...' : 'Continue'}</AssessmentButton>}
    </div>
  )
}

// internal assessment state to protobuf assessment state
function encodeScreenState(state) {
  const encodeAnswer = (qid, value) => {
    if (typeof(value) === 'number') {
      return {questionid: qid, intvalue: value}
    }
    if (typeof(value) === 'string') {
      return {questionid: qid, stringvalue: value}
    }
    if (typeof(value) === 'boolean') {
      return {questionid: qid, boolvalue: value}
    }
    throw 'could not encode answer'
  }
  let pbstate = {answers: []}
  Object.keys(state.answers).forEach(qid => {
    const pbanswer = encodeAnswer(qid, state.answers[qid])
    pbstate.answers.push(pbanswer)
  })
  return pbstate
}

// protobuf assessment state to internal assessment state
function decodeAssessmentState(pbstate) {
  const decodeAnswer = ({questionid, stringvalue, intvalue, boolvalue}) => {
    if (stringvalue !== undefined) {
      return [questionid, stringvalue]
    }
    if (intvalue !== undefined) {
      return [questionid, intvalue]
    }
    if (boolvalue !== undefined) {
      return [questionid, boolvalue]
    }
    throw `could not decode assessment answer`
  }
  let state = {
    index: pbstate.index,
    isKickout: pbstate.iskickout,
    kickoutReason: pbstate.kickoutreason,
    isCompleted: pbstate.iscompleted,
    completed: pbstate.completed,
    answers: {}
  }
  pbstate.answers.forEach(a => {
    const [qid, value] = decodeAnswer(a)
    state.answers[qid] = value
  })
  return state
}


// an api aware assessment, can make api calls, passes state down to the Assessment component
export function APIAssessment({assessmentId, client}) {
  const [spec, setSpec] = useState(undefined)
  const [state, setState] = useState(undefined)
  // const [isLocked, setIsLocked]

  useEffect(() => {
    client.getState({assessmentid: assessmentId})
      .then(response => {
        const remoteSpec = JSON.parse(response.spec)
        const remoteState = decodeAssessmentState(response.state)
        setSpec(remoteSpec)
        setState(remoteState)
      })
      .catch(e => console.log(e))
  }, [])

  const onSubmit = (screenState) => {
    const encoded = encodeScreenState(screenState)
    client.submitScreen({assessmentid: assessmentId, screenstate: encoded})
      .then(response => {
        const nextState = decodeAssessmentState(response.state)
        setState(nextState)
      })
      .catch(e => console.log(e))
  }

  const onEvent = (e) => {
    // client.recordEvent(...) // todo
  }

  if (!spec || !state) {
    return null
  }
  return <Assessment spec={spec} state={state} onSubmit={onSubmit} onEvent={onEvent} />
}

// State {
//   index: number
//   isKickout: boolean
//   kickoutReason: string
//   isCompleted: boolean
//   completed: string
//   answers: Object
// }
export function Assessment({spec, state, onSubmit, onEvent}) {
  const [auditTrail, setAuditTrail] = useState([]) // trail of events
  const wrappedOnEvent = (e) => {
    setAuditTrail(t => [...t, e])
    onEvent(e)
  }
  return <Screen screenSpec={spec.children[state.index]} isLoading={false} onSubmit={onSubmit} onEvent={wrappedOnEvent} />
}

function findMetricValueForKey(metrics, key) {
  const screenIndices = Object.keys(metrics)
  for (let i = 0; i < screenIndices.length; i++) {
    const screenIndex = screenIndices[i]
    const metricsForScreen = metrics[screenIndex]
    const value = metricsForScreen[key]
    if (value !== undefined) {
      return value
    }
  }
  return undefined
}

// function BuilderComponentScreenTitle({text, onConfirmText}) {
//   return (
//     <AtlasKitInlineEdit
//       readView={() => <ComponentScreenTitle text={text || 'click to enter text'} />}
//       editView={({errorMessage, ...fieldProps}) => (
//         <div style={{display: 'inline-flex', flexDirection: 'column', alignItems: 'center', gap: '5px'}}>
//           <AtlasKitTextfield {...fieldProps} style={{fontSize: '18px', textAlign: 'center', color: 'blue'}} autoFocus/>
//         </div>
//       )}
//       onConfirm={onConfirmText}
//       defaultValue={text}
//     />
//   )
// }
//
// function BuilderComponentComponentParagraph({text, onConfirmText}) {
//   return (
//     <AtlasKitInlineEdit
//       readView={() => (
//         <ComponentParagraph text={text || 'click to enter text'} />
//       )}
//       editView={({errorMessage, ...fieldProps}) => (
//         <AtlasKitTextarea {...fieldProps} style={{fontSize: '18px', margin: 0, lineHeight: 'normal'}} autoFocus />
//       )}
//       onConfirm={onConfirmText}
//       defaultValue={text}
//     />
//   )
// }
//
// function BuilderComponentComponentTextfield({label, onConfirmLabel}) {
//   return (
//     <AtlasKitInlineEdit
//       readViewFitContainerWidth
//       readView={() => (
//         <ComponentTextfield label={label || 'click to enter text'} />
//       )}
//       editView={({errorMessage, ...fieldProps}) => (
//         <div>
//           <AtlasKitTextfield {...fieldProps} autoFocus style={{fontSize: '18px'}}/>
//           <Textfield />
//         </div>
//       )}
//       onConfirm={onConfirmLabel}
//       defaultValue={label}
//     />
//   )
// }
//
// // onChangeNode(updatedNode)
// function createBuilderNode(node, onContinue, onChangeNode) {
//   if (node.type === 'screen') {
//     return [ComponentScreen, {}]
//   } else if (node.type === 'row') {
//     return [ComponentRow, {justify: node.justify}]
//   } else if (node.type === 'screen-footer') {
//     return [ComponentScreenFooter, {}]
//   } else if (node.type === 'screen-title') {
//     return [BuilderComponentScreenTitle, {text: node.text, onConfirmText: t => onChangeNode({...node, text: t})}]
//   } else if (node.type === 'paragraph') {
//     return [BuilderComponentComponentParagraph, {text: node.text, onConfirmText: t => onChangeNode({...node, text: t})}]
//   } else if (node.type === 'textfield') {
//     return [BuilderComponentComponentTextfield, {label: node.label, onConfirmLabel: l => onChangeNode({...node, label: l})}]
//   } else if (node.type === 'radio-item') {
//     return [ComponentRadioItem, {id: node.id, label: node.label, options: node.options}]
//   } else if (node.type === 'continue-button') {
//     return [ComponentContinueButton, {onClick: onContinue}]
//   }
//   return [() => {return null}, {}] // default
// }
//
// export function AssessmentBuilder({spec, onChangeSpec}) {
//   const [currentScreenIndex, setCurrentScreenIndex] = useState(0)
//   const currentScreenSpec = spec.children[currentScreenIndex]
//
//   const onContinue = () => {
//     setCurrentScreenIndex(currentScreenIndex => Math.min(currentScreenIndex + 1, spec.children.length - 1))
//   }
//   const onChangeNode = (updatedNode) => {
//     onChangeSpec(updateNodeById(spec, updatedNode.id, {...updatedNode}))
//   }
//   return createTree(currentScreenSpec, (node) => createBuilderNode(node, onContinue, onChangeNode))
// }

export function AssessmentBuilder() {
  return <div>assessment builder work in progress</div>
}
