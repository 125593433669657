/** @jsxImportSource @emotion/react */

const baseStyle = {
  border: '0',
  fontWeight: 'bold',
  fontSize: '18px',
  '&:not(:disabled)': {
    cursor: 'pointer',
  },
  '&:hover:not(:disabled)': {
    filter: 'brightness(1.2)',
  }
}

const primaryStyle = {
  color: 'white',
  backgroundColor: 'rgb(89,51,245)',
  padding: '14px',
  borderRadius: '10px',
  boxSizing: 'border-box',
  '&:disabled': {
    backgroundColor: 'grey'
  }
}

const secondaryStyle = {
  color: 'rgb(89,51,245)',
  backgroundColor: 'transparent',
  '&:disabled': {
    color: 'grey'
  }
}

export default function Button({type, style, children, ...rest}) {
  let buttonStyle = {...baseStyle, ...primaryStyle} // primary by default
  if (type === 'secondary') {
    buttonStyle = {...baseStyle, ...secondaryStyle}
  }
  buttonStyle = {...buttonStyle, ...style} // allow for style overrides
  return <button css={buttonStyle} {...rest}>{children}</button>
}
