import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import PageContainer from '../components/PageContainer'
import RouterLink from '../components/RouterLink'
import Button from '../components/Button'

function TruncateUUID({children}) {
  return `${children.split('-')[0]}...`
}

function Timestamp({children}) {
  const d = new Date(children)
  return d.toLocaleString()
}

export default function TakeAssessment({client}) {
  const [assessments, setAssessments] = useState([])
  useEffect(() => {
    client.getAssessments({})
      .then(response => setAssessments(response.items))
      .catch(e => console.log(e))
  }, [])

  const navigate = useNavigate()
  const takeAssessment = (specid) => {
    navigate(`/take-assessment/${specid}`)
  }
  return (
    <main style={{display: 'flex', justifyContent: 'center'}}>
      <div>
        <h1>Assessments</h1>
        <table style={{borderSpacing: '20px'}}>
          <thead>
            <tr style={{textAlign: 'left'}}>
              <th>Assessment ID</th>
              <th>Name</th>
              <th>Created</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {
              assessments.map(({specid, created}, i) => (
                <tr key={i} >
                  <td><TruncateUUID>{specid}</TruncateUUID></td>
                  <td>mock name</td>
                  <td><Timestamp>{created}</Timestamp></td>
                  <td>mock status</td>
                  <td><Button onClick={() => takeAssessment(specid)}>Begin Assessment</Button></td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </main>
  )
}
