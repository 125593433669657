import { useState, useEffect } from 'react'
import { createBlankSpec } from '../spec/templates'
import {
  useNavigate,
} from "react-router-dom";
import Button from '../components/Button'
import Modal from '../components/Modal'
import Textfield, { WithLabel } from '../components/Textfield'
import PageContainer from '../components/PageContainer'

function DashboardModalContainer({children}) {
  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: '40px', width: '350px'}}>
      {children}
    </div>
  )
}

function DashboardModalTitle({children}) {
  return <h2 style={{margin: 0}}>{children}</h2>
}

function DashboardModalText({children}) {
  return <p>{children}</p>
}

function DashboardModalButtonsContainer({children}) {
  return (
    <div style={{display: 'flex', justifyContent: 'flex-end', gap: '20px'}}>
      {children}
    </div>
  )
}

function CreateAssessmentModal({onCancel, onSave}) {
  const [name, setName] = useState('')
  return (
    <Modal onClose={onCancel}>
      <DashboardModalContainer>
        <DashboardModalTitle>Create New Assessment</DashboardModalTitle>
        <WithLabel id="name" label="Name:">
          <Textfield id="name" value={name} onChange={e => setName(e.target.value)} />
        </WithLabel>
        <DashboardModalButtonsContainer>
          <Button type="secondary" onClick={onCancel}>Cancel</Button>
          <Button style={{minWidth: '120px'}} onClick={() => onSave(name)}>Save</Button>
        </DashboardModalButtonsContainer>
      </DashboardModalContainer>
    </Modal>
  )
}

function DeleteAssessmentModal({name, onCancel, onDelete}) {
  return (
    <Modal onClose={onCancel}>
      <DashboardModalContainer>
        <DashboardModalTitle>Do you want to delete this entire assessment?</DashboardModalTitle>
        <DashboardModalText>Deleting <b><i>{name}</i></b> will delete all screens created in this assessment and cannot be undone. Are you sure you want to delete?</DashboardModalText>
        <DashboardModalButtonsContainer>
          <Button type="secondary" onClick={onCancel}>Go Back</Button>
          <Button style={{backgroundColor: 'red'}} onClick={onDelete}>Delete</Button>
        </DashboardModalButtonsContainer>
      </DashboardModalContainer>
    </Modal>
  )
}

function RenameAssessmentModal({onCancel, onRename}) {
  const [name, setName] = useState('')
  return (
    <Modal onClose={onCancel}>
      <DashboardModalContainer>
        <DashboardModalTitle>Rename Assessment</DashboardModalTitle>
          <WithLabel id="newname" label="Assessment Name:">
            <Textfield id="newname" value={name} onChange={e => setName(e.target.value)} />
          </WithLabel>
        <DashboardModalButtonsContainer>
          <Button type="secondary" onClick={onCancel}>Cancel</Button>
          <Button onClick={() => onRename(name)}>Save</Button>
        </DashboardModalButtonsContainer>
      </DashboardModalContainer>
    </Modal>
  )
}

function ScreenPreview() {
  return <div style={{width: '180px', height: '270px', backgroundColor: 'whitesmoke', borderRadius: '10px'}}></div>
}

function DashboardItem({name, created, numScreens, onClickDelete, onClickRename, onClickEdit, onClickPreview}) {
  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'space-between', margin: '40px 0'}}>
        <div style={{display: 'inline-block'}}>
          <h2 style={{margin: 0, fontSize: '18px', fontWeight: 'normal'}}>{name}</h2>
          <span style={{fontSize: '14px', color: 'grey'}}>{created}</span>
        </div>
        <div style={{display: 'flex', gap: '10px'}}>
          <Button type="secondary" disabled onClick={onClickDelete}>Delete</Button>
          <Button type="secondary" disabled onClick={onClickRename}>Rename</Button> {/* needs backend implementation */}
          <Button type="secondary" disabled onClick={onClickEdit}>Edit</Button>
          <Button type="secondary" onClick={onClickPreview}>Preview</Button>
          <Button disabled>Deploy</Button>
        </div>
      </div>
      <div style={{display: 'grid', gridTemplateColumns: '180px 180px 180px 180px', gap: '20px'}}>
        {Array(numScreens).fill().map((_, i) => <ScreenPreview key={i} />)}
      </div>
    </div>
  )
}

export default function Dashboard({client}) {
  const [items, setItems] = useState([])
  useEffect(() => {
    client.getAssessments({})
      .then(response => setItems(response.items.map(item => ({...item, spec: JSON.parse(item.spec)}))))
      .catch(e => console.log(e))
  }, [])

  const [createModal, setCreateModal] = useState({
    show: false
  })

  const [deleteModal, setDeleteModal] = useState({
    show: false,
    id: '',
    name: ''
  })

  const [renameModal, setRenameModal] = useState({
    show: false,
    id: ''
  })

  // Click Actions
  const clickActionCreate = () => {
    setCreateModal({show: true})
  }

  const clickActionDelete = (id, name) => {
    setDeleteModal({show: true, id: id, name: name})
  }

  const clickActionRename = (id) => {
    setRenameModal({show: true, id: ''})
  }

  const navigate = useNavigate()
  const clickActionEdit = (id) => {
    navigate(`/edit-assessment/${id}`)
  }

  const clickActionPreview = (id) => {
    navigate(`/preview-assessment/${id}`)
  }

  // Modal Actions
  const createAssessmentModalOnCancel = () => {
    setCreateModal({show: false})
  }

  const createAssessmentModalOnSave = async (name) => {
    // todo - needs updated
    // const assessment = await api.saveNewAssessment(name, createBlankSpec()) // save remotely
    // setLocalAssessments([assessment, ...localAssessments]) // update ui to reflect changes
    // setCreateModal({show: false})
  }

  const deleteAssessmentModalOnCancel = () => {
    setDeleteModal({show: false, id: '', name: ''})
  }

  const deleteAssessmentModalOnDelete = async () => {
    // todo - needs updated
    // await api.deleteById(deleteModal.id) // delete remotely
    // setLocalAssessments(localAssessments.filter(a => a.id !== deleteModal.id)) // update ui to reflect changes
    // setDeleteModal({show: false, id: '', name: ''})
  }

  const renameAssessmentModalOnCancel = () => {
    setRenameModal({show: false, id: ''})
  }

  const renameAssessmentModalOnRename = async (name) => {
    // todo - needs updated
    // await api.renameById(renameModal.id, name) // rename remotely
    // setLocalAssessments(localAssessments.map(a => { // update ui to reflect changes
    //   if (a.id === renameModal.id) {
    //     return {...a, name: name}
    //   }
    //   return a
    // }))
    // setRenameModal({show: false, id: ''})
  }

  return (
    <PageContainer>
      {createModal.show && <CreateAssessmentModal onCancel={createAssessmentModalOnCancel} onSave={createAssessmentModalOnSave} /> }
      {deleteModal.show && <DeleteAssessmentModal onCancel={deleteAssessmentModalOnCancel} onDelete={deleteAssessmentModalOnDelete} name={deleteModal.name} /> }
      {renameModal.show && <RenameAssessmentModal onCancel={renameAssessmentModalOnCancel} onRename={renameAssessmentModalOnRename} /> }
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '40px 0'}}>
        <h1>Dashboard</h1>
        <Button disabled onClick={() => setCreateModal({show: true})}>Create New Assessment</Button>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', gap: '40px'}}>
        {items.map(item =>
          <DashboardItem
            key={item.specid}
            name="mock name"
            created={item.created}
            numScreens={item.spec.children.length}
            onClickDelete={() => clickActionDelete(item.specid, item.name)}
            onClickRename={() => clickActionRename(item.specid)}
            onClickEdit={() => clickActionEdit(item.specid)}
            onClickPreview={() => clickActionPreview(item.specid)} />
        )}
      </div>
    </PageContainer>
  )
}
